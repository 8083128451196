<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Filters">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Officers">
          <asom-multi-select
            v-model="filters.officers"
            :loading="isLoading"
            :options="officerOptions"
            @search-change="queryOfficerList"
          />
        </asom-form-field>
        <asom-form-field label="Stations">
          <asom-multi-select
            placeholder="Select Stations"
            v-model="filters.stations"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="Date Created">
          <asom-input-date-range
            v-model="filters.notificationDate"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field label="Acknowledged By Date">
          <asom-input-date-range
            v-model="filters.acknowledgedByDate"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <!-- <asom-form-field label="Acknowledgement Status">
          <asom-input-radio-group
            v-model="filters.acknowledgement"
            :options="acknowledgements"
          />
        </asom-form-field> -->
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>

    <div v-if="isLoading" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div v-else class="space-y-4">
      <asom-card title="Acknowledgement Summary By Users">
        <asom-client-table
          :columns="[
            'recipientName',
            'total',
            'acknowledged',
            'pendingAcknowledgement',
            'action',
          ]"
          :sortableColumns="[
            'recipientName',
            'total',
            'acknowledged',
            'pendingAcknowledgement',
            'action',
          ]"
          :data="userSummaryData"
        >
          <template v-slot:header_recipientName>Officer</template>
          <template v-slot:header_action><span></span></template>
          <template v-slot:action="scopedSlots">
            <asom-button
              @click="viewDetails(scopedSlots.rowData)"
              text="View Pending List"
            />
          </template>
        </asom-client-table>
      </asom-card>
      <asom-card title="Acknowledgement Status Details">
        <asom-client-table
          :columns="[
            'cpUpdateDetails',
            'recipientName',
            'recipientRole',
            'recipientStationName',
            'recipientLine',
            'status',
            'dateOfAckNowledgement',
          ]"
          :sortableColumns="[
            'cpUpdateDetails',
            'status',
            'dateOfAckNowledgement',
            'recipientName',
            'recipientRole',
            'recipientStationName',
            'recipientLine',
          ]"
          :data="itemList"
        >
          <template v-slot:header_cpUpdateDetails>CP Update No</template>
          <template v-slot:header_dateOfAckNowledgement
            >Acknowledgement Date</template
          >
          <template v-slot:header_recipientStationName>Station</template>
          <template v-slot:header_recipientLine>Line</template>
          <template v-slot:status="scopedSlots">
            {{ acknowlegementStatus(scopedSlots.rowData) }}
          </template>
          <template v-slot:cpUpdateDetails="scopedSlots">
            <button
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ get(scopedSlots.data, "cpUpdateNo") }}
            </button>
          </template>
          <template v-slot:dateOfAckNowledgement="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:isAcknowledged="scopedSlots">
            <span>{{ scopedSlots.data ? "YES" : "NO" }}</span>
          </template>
        </asom-client-table>
      </asom-card>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import moment from "moment";
import { displayUtcDate, toMoment} from "@/helpers/dateTimeHelpers";
import { getUserAcknowledgementSummary } from "../../../services/opsComms.service";
import { mapGetters } from "vuex";
import { listUsers } from "../../../services/user.service";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  data() {
    return {
      isLoading: false,
      itemList: [],
      error: null,
      filters: {
        officers: get(this.filterValues, "officers", []),
        stations: get(this.filterValues, "stations", []),
        notificationDate: get(this.filterValues, "notificationDate", {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        }),
        acknowledgedByDate: get(this.filterValues, "acknowledgedByDate", {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        }),
        //isAcknowledgedOnly: true,
      },
      officerOptions: [],
      isLoadingOfficers: false,
    };
  },
  mounted() {
    const searchVal = getStorage("user-report");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }else {
      this.filters.notificationDate = get(
        this.filterValues,
        "notificationDate",
        {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        }
      );
      this.filters.acknowledgedByDate = get(
        this.filterValues,
        "acknowledgedByDate",
        {
          start: moment()
            .subtract(1, "months")
            .toDate(),
          end: moment()
            .add(1, "months")
            .toDate(),
        }
      );
    }
    this.$nextTick(() => {
      this.filters.officers = get(this.filterValues, "officers", []);
      this.filters.stations = get(this.filterValues, "stations", []);
      this.getPageData();
    })
  },
  computed: {
    ...mapGetters({
      stationOptions: "smrtResource/stationOptions",
      filterValues: "opsComms/getFilterValues",
    }),
    acknowledgements() {
      return [
        {
          label: "Pending Acknowledgements",
          value: false,
        },
        {
          label: "Completed Acknowledgements",
          value: true,
        },
      ];
    },
    queryParams() {
      let params = {};
      if (this.filters.officers) {
        params["userIds"] = this.filters.officers.map((option) => option.value);
      }
      if (this.filters.stations) {
        params["stationIds"] = this.filters.stations.map(
          (station) => station.value
        );
      }
      if (
        this.filters.notificationDate &&
        this.filters.notificationDate.start &&
        this.filters.notificationDate.end
      ) {
        params["notificationDateFrom"] = this.parseDateTimeUTC(
          this.filters.notificationDate.start, true
        );
        params["notificationDateTo"] = this.parseDateTimeUTC(
          this.filters.notificationDate.end, false
        );
      }
      if (
        this.filters.acknowledgedByDate &&
        this.filters.acknowledgedByDate.start &&
        this.filters.acknowledgedByDate.end
      ) {
        params["acknowledgedByDateFrom"] = this.parseDateTimeUTC(
          this.filters.acknowledgedByDate.start, true
        );
        params["acknowledgedByDateTo"] = this.parseDateTimeUTC(
          this.filters.acknowledgedByDate.end, false
        );
      }
      // if (this.filters.acknowledgement) {
      //   if (get(this.filters.acknowledgement, "value") == "pending") {
      //     params["isPendingOnly"] = true;
      //     params["isAcknowledgedOnly"] = false;
      //   } else if (get(this.filters.acknowledgement, "value") == "completed") {
      //     params["isAcknowledgedOnly"] = true;
      //     params["isPendingOnly"] = false;
      //   }
      // }
      return params;
    },
    userSummaryData() {
      if (!this.itemList) return [];
      const groupByUsers = groupBy(this.itemList, (item) => item.recipientId);
      const userIds = Object.keys(groupByUsers);
      const data = userIds.map((id) => ({
        recipientId: id,
        recipientName: get(groupByUsers, `${id}.0.recipientName`),
        total: groupByUsers[id].length,
        acknowledged: groupByUsers[id].filter((item) => item.isAcknowledged)
          .length,
        pendingAcknowledgement: groupByUsers[id].filter(
          (item) => !item.isAcknowledged
        ).length,
      }));

      return sortBy(data, "recipientName");
    },
  },
  methods: {
    get,
    displayUtcDate,
    acknowlegementStatus({ isAcknowledged, cpUpdateDetails }) {
      const expiryDate = moment(get(cpUpdateDetails, "expiryDate"));
      const today = moment(Date.now());
      const isExpired =
        expiryDate.isValid() && expiryDate.isBefore(today, "day");
      if (isAcknowledged) return "Acknowledged";
      else if (isExpired) return "Expired";
      else {
        return "Pending Acknowledgement";
      }
    },
    resetFilters() {
      this.filters.officers = [];
      this.filters.stations = [];
      this.filters.notificationDate = {
        start: moment()
          .subtract(1, "months")
          .toDate(),
        end: moment()
          .add(1, "months")
          .toDate(),
      };
      this.filters.acknowledgedByDate = {
        start: moment()
          .subtract(1, "months")
          .toDate(),
        end: moment()
          .add(1, "months")
          .toDate(),
      };
      //this.filters.acknowledgement = null;
    },
    async queryOfficerList(query) {
      this.isLoadingOfficers = true;
      if (!query) {
        this.isLoadingOfficers = false;
        this.officerOptions = [];
      }
      const result = await listUsers({ search: query.trim() });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingOfficers = false;
        this.error = null;
        this.officerOptions = get(result, "payload.list").map(
          ({ id, name }) => ({
            label: name,
            value: id,
          })
        );
      } else {
        this.isLoadingOfficers = false;
        this.error = result.payload;
        this.officerOptions = [];
      }
    },
    async getPageData(status) {
      if(status) setStorage("user-report", this.filters);
      await this.$store.dispatch(
        "opsComms/setOfficerAcknowledgementReportsPageFilters",
        this.filters
      );
      this.isLoading = true;
      const resp = await getUserAcknowledgementSummary({
        ...this.queryParams,
      });
      if (resp.success) {
        this.itemList = get(resp.payload, "list", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    navigateToView(selectedItem) {
      this.$router.push({
        name: "View CP Update",
        params: selectedItem,
      });
    },
    viewDetails(selectedItem) {
      this.$router.push({
        name: "Individual Officer Acknowledgement Report",
        params: selectedItem,
      });
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf('day') : d.endOf('day');
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss")
      }
      return ""
    }
  },
};
</script>
